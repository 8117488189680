/*----------------------------------------------------------------
#    Project:	etwinning2014 - IE8 et 9
#    Version:	1.0.0
#    Date:	25 août 2014, 11:58:52
#    Author:	aurelien.jean
#----------------------------------------------------------------*/

#contenu .content_wrap #bureau_national li {min-height: 29em;}
#contenu article #resume_projet li span:last-child {max-width: 305px;}

#sub_header.accueil {
	background-image: url('../Images/carte_accueil.png');
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: scroll;
}
.accueil#sub_header .overlay-logo {
	background-image:url('../Images/logo.png');
	background-position:bottom right;
	background-repeat: no-repeat;
	background-size: auto;
}


@media (max-width: 50em) {
	.svg #main_header {
		background-image: url('../Images/logo.png');
		background-position: 70px 13px;
		background-repeat: no-repeat;
		background-size: auto;
	}
}

@media (max-width: 30em) {
	#sub_header.accueil {
		background-image: none;
	}
	#contenu .content_wrap #bureau_national li {min-height:auto;}

}
